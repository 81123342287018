.rc-mentions {
  display: inline-block;
  position: relative;
  white-space: pre-wrap;
}
.rc-mentions > textarea,
.rc-mentions-measure {
  font-size: inherit;
  font-size-adjust: inherit;
  font-style: inherit;
  font-variant: inherit;
  font-stretch: inherit;
  font-weight: inherit;
  font-family: inherit;
  padding: 0;
  margin: 0;
  line-height: inherit;
  vertical-align: top;
  overflow: inherit;
  word-break: inherit;
  white-space: inherit;
  word-wrap: break-word;
  overflow-x: initial;
  overflow-y: auto;
  text-align: inherit;
  letter-spacing: inherit;
  tab-size: inherit;
  direction: inherit;
}
.rc-mentions > textarea {
  border: none;
  width: 100%;
  background: transparent;
  outline: none;
  color: var(--colors-fgDefault);
}

.rc-mentions > textarea::placeholder {
  color: var(--colors-fgMuted);
}

.rc-mentions-measure {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  color: transparent;
  z-index: 1;
}

.rc-mentions-dropdown {
  position: absolute;
  border: none;
}

.rc-mentions-dropdown-menu {
  margin: 0;
  padding: 0;
  max-height: 250px;
  overflow-y: scroll;
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  background: var(--colors-bgSurface);
  border: 1px solid var(--colors-borderMuted);
  font-size: var(--fontSizes-small);
  padding: var(--space-2);
  border: 1px solid var(--colors-borderMuted);
  border-radius: var(--radii-medium);
}

/* width */
.rc-mentions-dropdown-menu::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.rc-mentions-dropdown-menu::-webkit-scrollbar-track {
  background: var(--canvas-Muted);
}

/* Handle */
.rc-mentions-dropdown-menu::-webkit-scrollbar-thumb {
  background: var(--colors-borderMuted);
  border-radius: 10px;
}

.rc-mentions-dropdown-menu-item {
  cursor: pointer;
  min-width: 150px;
  line-height: 1;
  color: var(--colors-fgDefault);
  border-radius: var(--radii-small);
  display: flex;
  align-items: center;
  padding: var(--space-3);
  position: relative;
  user-select: none;
  background: transparent;
  font-weight: var(--fontWeights-sans);
}
.rc-mentions {
  font-size: var(--fontSizes-base);
  color: var(--colors-fgDefault);
}
.rc-mentions-dropdown {
  border-radius: var(--radii-medium);
}
.rc-mentions-dropdown-menu-item {
  padding: var(--space-3);
}
.rc-mentions-dropdown-menu-item-active {
  background: var(--colors-accentBg);
}
